import * as S from './GameScreen.styles'

interface InfoProps {
  nextEvent: number,
  raceName: string,
  numberRaces: number,
  points: number,
  podiums: number,
  wonRaces: number
}

function Info({nextEvent, raceName, numberRaces, points, podiums, wonRaces} : InfoProps) {

  return (
    <S.Info>
      <div>Days till next event: {nextEvent} / {raceName}</div>
      <div>Took part in {numberRaces} races.</div>
      <div>Points this season: {points}</div>
      <div>Podiums: {podiums}</div>
      <div>Wins: {wonRaces}</div>
    </S.Info>
  )
}

export default Info