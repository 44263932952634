import * as S from './GameScreen.styles'

interface StatProps {
  day: number,
  season: number,
  energy: number,
  money: number
}

function Stats({day, season, energy, money} : StatProps) {
  const info = 'Every 7 days there is a race. To participate in each race you need 50€, so dont forget to work, afterall you are not Stroll. Podium positions net 200/100/50€. Currently you are running in the Formula VEE, which has 10 drivers, top 5 get points. Actions cost energy except for racing, so use the last day to prepare.'

  return (
    <S.Stats>
      <div>Day: {day} / Season: {season}</div>
      <div>Your Energy: {energy}</div>
      <div>Your Money: {money} €</div>
      <S.GetInfo onClick={() => alert(info)}>Info</S.GetInfo>
    </S.Stats>
  )
}

export default Stats