import React from 'react'
import GameScreen from './components/GameScreen'
import * as S from './App.styles'

function App() {
  return (
    <S.Container>
      <GameScreen />
      <S.Footer>© 2022 Vladyslav Chernyshov • <a href="mailto:hello@vladyslav.ch">Get in touch</a></S.Footer>
    </S.Container>
  );
}

export default App;
