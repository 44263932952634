import React, { useCallback, useEffect, useState } from 'react'
import * as S from './GameScreen.styles'
import Stats from './Stats'
import Info from './Info'
import Skills from './Skills'
import eventsRaw from './eventsRaw.json'

interface IEvents {
  name: string,
  positive: string,
  negative: string,
  actionPositive: number,
  actionNegative: number
}

const events = eventsRaw as IEvents[]

function GameScreen() {
  const [day, setDay] = useState(0)
  const [season, setSeason] = useState(1)
  const [energy, setEnergy] = useState(100)
  const [activeButton, setActiveButton] = useState(false)
  const [overtake, setOvertake] = useState(1)
  const [defense, setDefense] = useState(1)
  const [pace, setPace] = useState(1)
  const [consistency, setConsistency] = useState(1)
  const [tyre, setTyre] = useState(1)
  const [fuel, setFuel] = useState(1)
  const [wet, setWet] = useState(1)
  const [money, setMoney] = useState(65)
  const [nextEvent, setNextEvent] = useState(7)
  const [nextSpecialEvent, setNextSpecialEvent] = useState(3)
  const [nextDayButton, setNextDayButton ] = useState(false)
  const [firstRace, setRace] = useState(true)
  const [numberRaces, setNumberRaces] = useState(0)
  const [wonRaces, setWon] = useState(0)
  const [podiums, setPodiums] = useState(0)
  const [points, setPoints] = useState(0)
  const [raceMultiplier, setRaceMultiplier] = useState(1)
  const [raceName, setRaceName] = useState('Monza')
  const [position, setPosition] = useState('')

  const randomEvents = useCallback(
    () => { 
      const keys = Object.keys(events)
      const randomIndex : any = keys[Math.floor(Math.random() * keys.length)]
      const item = events[randomIndex]

      if (window.confirm(item.name)){
        alert(item.positive)
        if (item.actionPositive === 1) {
          console.log('you gained fans (when that gets implemented)')
        } else if(item.actionPositive === 2) {
          setMoney(money + 30)
        } else if (item.actionPositive === 3) {
          setPace(pace + 3)
        } else {
          setOvertake(overtake + 2)
        }
      } else {
        alert(item.negative)
        if (item.actionNegative === 1) {
          console.log('you lost fans (when that gets implemented)')
        } else if(item.actionNegative === 2) {
          setMoney(money - 30)
        } else if (item.actionNegative === 3) {
          console.log('actionpositive 3')
        } else {
          setConsistency(consistency + 2)
        }
      }
      setNextSpecialEvent(7)
    },
    [money, pace, overtake, consistency],
  )

  useEffect(() => {
    if (nextSpecialEvent === 0) {
      randomEvents()
    }
  }, [nextSpecialEvent, randomEvents])

  useEffect(() => {
    if (numberRaces === 0) {
      setRaceMultiplier(1)
      setRaceName('Monza')
    } else if (numberRaces === 1) {
      setRaceMultiplier(1)
      setRaceName('Silverstone')
    } else if (numberRaces === 2 && wet > 3) {
      setRaceMultiplier(1.5)
      setRaceName('Spa (W)')
    } else if (numberRaces === 2 && wet <= 3) {
      setRaceMultiplier(0.5)
      setRaceName('Spa (W)')
    } else if (numberRaces === 3 && wet > 3) {
      setRaceMultiplier(1.5)
      setRaceName('Suzuka (W)')
    } else if (numberRaces === 3 && wet <= 3) {
      setRaceMultiplier(0.5)
      setRaceName('Suzuka (W)')
    } else if (numberRaces === 4 && consistency >= 3) {
      setRaceMultiplier(1.5)
      setRaceName('Monaco')
    } else {
      setRaceMultiplier(0.5)
      setRaceName('Monaco')
    }
  }, [numberRaces, wet, consistency])

  function addDays(day: number) {
    setDay(day + 1)
    setEnergy(100)
    setNextEvent(nextEvent - 1)
    setNextSpecialEvent(nextSpecialEvent - 1)
    setActiveButton(false)
  }

  function checkEnergyLevels(energy: number) {
    if (energy < 10) {
      setActiveButton(true)
    }
  }

  useEffect(() => {
    checkEnergyLevels(energy)
  }, [energy])

  useEffect(() => {
    if (nextEvent < 1){
      setRace(false)
      setNextDayButton(true)
    } else {
      setRace(true)
      setNextDayButton(false)
    }
  }, [day, money, nextEvent])

  function addOvertake(energy: number, finalEnergy: number, overtake: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setOvertake(overtake + 0.15)
  }

  function addDefense(energy: number, finalEnergy: number, defense: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setDefense(defense + 0.15)
  }

  function addPace(energy: number, finalEnergy: number, pace: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setPace(pace + 0.15)
  }

  function addConsistency(energy: number, finalEnergy: number, consistency: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setConsistency(consistency + 0.2)
  }

  function addTyre(energy: number, finalEnergy: number, tyre: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setTyre(tyre + 0.2)
  }

  function addFuel(energy: number, finalEnergy: number, fuel: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setFuel(fuel + 0.2)
  }

  function addWet(energy: number, finalEnergy: number, wet: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setWet(wet + 0.2)
  }

  function work(energy: number, finalEnergy: number, money: number) {
    setEnergy(Math.floor(energy - finalEnergy))
    setMoney(money + 1.25)
  }

  const finalEnergy = 10

  function theRace() {
    const playerSkill = overtake + defense + pace + consistency + tyre + fuel + wet
    const d20 = Math.floor(Math.random()*20+1)
    const powerLevel = ((playerSkill + d20) * raceMultiplier)/1.2
    setMoney(money - 50)
    setNumberRaces(numberRaces + 1)

    const adv1 = 90
    const adv2 = 85
    const adv3 = 80
    const adv4 = 75
    const adv5 = 70
    const adv6 = 60
    const adv7 = 55
    const adv8 = 45
    const adv9 = 30

    if (powerLevel < adv9) {
      setNextEvent(7)
      alert('you came dead last, go practice')
    } else if (powerLevel < adv8) {
      setNextEvent(7)
      alert('did you even try? 9th')
    } else if (powerLevel < adv7) {
      setNextEvent(7)
      alert('wow... you barely finished. 8th')
    } else if (powerLevel < adv6) {
      setNextEvent(7)
      alert('i forgot you were even racing. 7th')
    } else if (powerLevel < adv5) {
      setNextEvent(7)
      alert('you came dead last...nah just kidding. It was 6th but does it matter?')
    } else if (powerLevel < adv4) {
      setNextEvent(7)
      setPoints(points + 1)
      alert('wow!!! you came 5th... not impressed')
    } else if (powerLevel < adv3) {
      setNextEvent(7)
      setPoints(points + 2)
      alert('you could smell the podium but you bottled it. 4th')
    } else if (powerLevel < adv2) {
      setNextEvent(7)
      setPoints(points + 4)
      alert('hey you you came 3rd... that is the same as losing')
      setMoney(money + 50)
      setPodiums(podiums + 1)
    } else if (powerLevel < adv1) {
      setPoints(points + 7)
      setNextEvent(7)
      alert('better than third I guess. 2nd')
      setMoney(money + 100)
      setPodiums(podiums + 1)
    } else {
      setPoints(points + 10)
      setNextEvent(7)
      alert('you won!')
      setMoney(money + 200)
      setPodiums(podiums + 1)
      setWon(wonRaces + 1)
    }
  }

  useEffect(() => {
    if (season === 1 && numberRaces === 5) {
      alert(`Congratulations for finishing your rookie season. You finished with ${points} points. You came... ${position}.`)
      setSeason(season + 1)
      setPoints(0)
      setNumberRaces(0)
      setPodiums(0)
      setWon(0)
    } else if (numberRaces === 5) {
      alert(`Congratulations for finishing the season. You finished with ${points} points. You came... ${position}.`)
      setSeason(season + 1)
      setPoints(0)
      setNumberRaces(0)
      setPodiums(0)
      setWon(0)
    }
  }, [numberRaces, season, points, position])

  useEffect(() => {
    if ( points > 20 &&  points < 30) {
      setPosition('Third!')
    } else if ( points >= 30 && points <= 39 ) {
      setPosition('Second!')
    } else if ( points > 39) {
      setPosition('First! Congratulations, you are the World Champion!')
    } else if (points <= 20) {
      setPosition(`Does it really matter? No FIA gala dinner for you. Try again next year`)
    } else  {
      console.log('i dunno dog')
    }
  }, [points])

  return (
    <S.GameScreen>
      <S.Data>
        <Stats day={day} season={season} energy={energy} money={money} />
        <Info nextEvent={nextEvent} raceName={raceName} numberRaces={numberRaces} points={points} podiums={podiums} wonRaces={wonRaces} />
      </S.Data>
      <Skills overtake={overtake} defense={defense} pace={pace} consistency={consistency} tyre={tyre} fuel={fuel} wet={wet} />
      <S.TrainActions>
        <S.Button disabled={activeButton} onClick={() => addOvertake(energy, finalEnergy, overtake)} >Train Overtake (+0.15S)</S.Button>
        <S.Button disabled={activeButton} onClick={() => addDefense(energy, finalEnergy, defense)} >Train Defense (+0.15S)</S.Button>
        <S.Button disabled={activeButton} onClick={() => addPace(energy, finalEnergy, pace)}>Train Pace (+0.15S)</S.Button>
        <S.Button disabled={activeButton} onClick={() => addConsistency(energy, finalEnergy, consistency)}>Train Consistency (+0.2S)</S.Button>
        <S.Button disabled={activeButton} onClick={() => addTyre(energy, finalEnergy, tyre)}>Train Tyre Management (+0.2S)</S.Button>
        <S.Button disabled={activeButton} onClick={() => addFuel(energy, finalEnergy, fuel)}>Train Fuel Management (+0.2S)</S.Button>
        <S.Button disabled={activeButton} onClick={() => addWet(energy, finalEnergy, wet)}>Train Wet Skill (+0.2S)</S.Button>
      </S.TrainActions>
      <S.DayActions>
        <S.Button disabled={activeButton} onClick={() => work(energy, finalEnergy, money)}>Work (+1.25€)</S.Button>
        <S.Button disabled={firstRace} onClick={() => theRace()}>Time to race</S.Button>
        <S.NextDay disabled={nextDayButton} onClick={() => addDays(day)}>Next Day</S.NextDay>
      </S.DayActions>
    </S.GameScreen>
  )
}

export default GameScreen