import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Footer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Catamaran&display=swap')

  margin-top: 20px;
  font-family: 'Catamaran', sans-serif;
  font-size: 12px;
`