import styled from 'styled-components'

export const GameScreen = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const Data = styled.div`
  display: flex;
  flex-direction: column;
`

export const GetInfo = styled.button`
  
`

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  margin-bottom: 10px;
  padding: 10px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  padding: 10px;
  margin-bottom: 10px;
`

export const Skills = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid blue;
  padding: 10px;
  margin-bottom: 10px;
`

export const TrainActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid blue;
  margin-bottom: 10px;
`

export const DayActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid green;
`

export const Button = styled.button`
  margin-bottom: 4px;
`

export const NextDay = styled.button`
margin-bottom: 10px;
  text-size: large;
`