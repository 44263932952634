import * as S from './GameScreen.styles'

interface SkillsProps {
  overtake: number,
  defense: number,
  pace: number,
  consistency: number,
  tyre: number,
  fuel: number,
  wet: number
}

function Skills({overtake,defense,pace,consistency,tyre,fuel,wet} : SkillsProps) {

  return (
    <S.Skills>
      <div>Your Overtake Skill: {Math.floor(overtake * 10) / 10}</div>
      <div>Your Defensive Skill: {Math.floor(defense * 10) / 10}</div>
      <div>Your Overall Pace: {Math.floor(pace * 10) / 10}</div>
      <div>Your Consistency Skill: {Math.floor(consistency * 10) / 10}</div>
      <div>Your Tyre Management Skill: {Math.floor(tyre * 10) / 10}</div>
      <div>Your Fuel Management Skill: {Math.floor(fuel * 10) / 10}</div>
      <div>Your Wet Skill: {Math.floor(wet * 10) / 10}</div>
    </S.Skills>
  )
}

export default Skills